export default {


	// baseUrl: 'http://tongmu.com',// api请求地址
	// imgDomain: 'http://tongmu.com/',// 图片域名
	// baseUrl: 'https://www.tom-aaa.com',
	// imgDomain: 'https://www.tom-aaa.com/',
	baseUrl: 'https://manage.tom-aaa.com',
	imgDomain: 'https://manage.tom-aaa.com/',
	// 腾讯地图key
	 mpKey: '',

	// 客服
	 webSocket: '',

	// api安全
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}
